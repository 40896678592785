import { Button, Modal } from "@mui/material";
import "./FilterCarIndex.scss";
import navbarImages from "../../../assets/images/navbar";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { ListCheckboxes } from "../../listCheckboxes/ListCheckboxes";
import { DropdownProps } from "../../dropdownCheckboxes/form";
import { IndexCarSearchForm } from "../../../views/car/index/form";
import { FiltersCarIndexContext } from "../../../providers/indexFiltersProvider";

type CarIndexOptionsProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  handleSubmitCheckBoxes: (
    checkboxName: "status" | "type" | "transmission",
    values: Pick<DropdownProps, "fields">
  ) => void;
  values: IndexCarSearchForm;
};

export const FilterCarIndex = ({
  setOpen,
  open,
  handleSubmitCheckBoxes,
  values,
}: CarIndexOptionsProps) => {
  const { t } = useTranslation(["car"]);
  const { status, transmission, type } = useContext(FiltersCarIndexContext);

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      className="modal-filter__car__index__container"
    >
      <div className="modal-content__filter__car__index">
        <div className="modal-header">
          <h2>{t("index.modals.filters.title")}</h2>
          <Button
            type="button"
            onClick={() => setOpen(false)}
            className="btn btn-orange btn-Filter"
          >
            <img src={navbarImages.crossBlack} alt="Close" />
          </Button>
        </div>
        <div className="modal-body">
          <ListCheckboxes
            name="Estatus"
            fields={status}
            fetchDropdown={(values) => {
              handleSubmitCheckBoxes("status", values);
            }}
          />
          <ListCheckboxes
            name="Tipo"
            fields={type}
            fetchDropdown={(values) => {
              handleSubmitCheckBoxes("type", values);
            }}
          />
          <ListCheckboxes
            name="Transmisión"
            fields={transmission}
            fetchDropdown={(values) => {
              handleSubmitCheckBoxes("transmission", values);
            }}
          />
        </div>
        <div className="modal-footer"></div>
      </div>
    </Modal>
  );
};
