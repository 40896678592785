import { Link } from "react-router-dom";
import { DropdownCheckboxes } from "../dropdownCheckboxes/DropdownCheckboxes";
import { useTranslation } from "react-i18next";
import { FiltersCarIndexContext } from "../../providers/indexFiltersProvider";
import { useContext } from "react";
import { DropdownProps } from "../dropdownCheckboxes/form";
import images from "../../assets/images/a_cars/index";

type ActionsCarIndexProps = {
  handleSubmitCheckBoxes: (
    checkboxName: "status" | "type" | "transmission",
    values: Pick<DropdownProps, "fields">
  ) => void;
};

export const ActionsCarIndex = ({
  handleSubmitCheckBoxes,
}: ActionsCarIndexProps) => {
  const { t } = useTranslation(["car"]);
  const { status, transmission, type } = useContext(FiltersCarIndexContext);

  return (
    <div className="a-cars__search__container__actions">
      <div className="a-cars__search__container__actions__dropdowns">
        <DropdownCheckboxes
          name="Estatus"
          fields={status}
          fetchDropdown={(values) => {
            handleSubmitCheckBoxes("status", values);
          }}
        />
        <DropdownCheckboxes
          name="Tipo"
          fields={type}
          fetchDropdown={(values) => {
            handleSubmitCheckBoxes("type", values);
          }}
        />
        <DropdownCheckboxes
          name="Transmisión"
          fields={transmission}
          fetchDropdown={(values) => {
            handleSubmitCheckBoxes("transmission", values);
          }}
        />
      </div>
      <Link
        to={`/${t("manage", { ns: "routes" })}/${t("my_cars", {
          ns: "routes",
        })}/${t("upload_car", { ns: "routes" })}`}
        data-testid="add-button"
        className="btn btn-add"
      >
        <img src={images.plus} alt="plus" aria-hidden="true" />
        <p>{t("index.add_car")}</p>
      </Link>
    </div>
  );
};
