import { AddressTrip } from "./../../models/addressTrip/types";
import { Address } from "../../models/address/types";
import dayjs from "dayjs";
import { Coupon } from "../../models/coupon/types";
import { Car } from "../../models/car/types";

export const formatTextWithEnters = (text: string | undefined) => {
  return `${text?.replace(/\n/g, "<br />")}`;
};

export const numberSeparator = (data: number) => {
  const dataSplit = data.toString().split(".");
  dataSplit[0] = dataSplit[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return dataSplit.join(".");
};

export const replaceIndex = (
  word: string,
  index: number,
  replacement: string
) => {
  return (
    word.substring(0, index) +
    replacement +
    word.substring(index + replacement.length)
  );
};

export const setCharAt = (str: string, index: number, chr: string) => {
  if (index > str.length - 1) return str;
  return str.substring(0, index) + chr + str.substring(index + 1);
};

export const capitalize = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1).toLocaleLowerCase();
};

export const addressPrice = (address: Address) =>
  address.price === "0" || address.price === ""
    ? "GRATIS"
    : `$${address.price}`;

export const addressValue = (address: Address) =>
  address.id +
  "-" +
  address.type +
  "-" +
  (address.price == "" ? "0" : parseFloat(address.price || ""));

export const mainAddressFormat = (address: Address | AddressTrip) =>
  address.suburb +
  ", " +
  address.zipCode +
  ", " +
  address.city +
  ", " +
  address.state +
  ", " +
  address.country;

export const formatIdHexToDecimal = (hexFormated: string) => {
  const hex = hexFormated.replace(/-/g, "");
  return (parseInt(hex, 16) - 2_684_424_192).toString();
};

export const formatIdDecimalToHex = (id: string) => {
  const hex = (parseInt(id) + 2_684_424_192).toString(16).toUpperCase();
  const hexSpaced = hex.match(/.{1,4}/g);
  return hexSpaced?.join("-");
};

// dd/mm/yyyy * 6:00pm
export const dateFormat = (date: string, hour: string) => {
  return (
    dayjs(date, "DD-mm-YYYY").format("DD/mm/YYYY") +
    " • " +
    dayjs(hour, "HH:mm:ss").format("h:mm A")
  );
};

export const discountType = (
  coupon: Coupon
): { type: "amount" | "percentage"; quantity: number } => {
  if (coupon.amountOff !== 0) {
    return { type: "amount", quantity: coupon.amountOff };
  } else {
    return { type: "percentage", quantity: coupon.percentOff };
  }
};

export const carShortInformation = (car: Car) => {
  return car.brand + " " + car.model + " " + car.year;
};

export const formatNumber = (n: number): string => {
  return new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
  }).format(n);
};

// Handle experience or accesories in a string
export const activeInListByPosition = (position: number, list?: string) => {
  if (!list) return false;

  return list[position] === "1";
};
