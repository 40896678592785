import { useFormik } from "formik";
import { Checkbox, FormControlLabel } from "@mui/material";
import carImages from "../../assets/images/car/index";
import "./ListCheckboxes.scss";
import {
  DropdownCheckboxesSchema,
  DropdownProps,
} from "../dropdownCheckboxes/form";

export const ListCheckboxes = ({
  name,
  fields,
  fetchDropdown,
}: DropdownProps) => {
  const formikListCheckboxes = useFormik<Pick<DropdownProps, "fields">>({
    initialValues: {
      fields,
    },
    validationSchema: DropdownCheckboxesSchema,
    onSubmit: fetchDropdown,
  });

  return (
    <div className="list-checkboxes">
      <h3 className="list-checkboxes__title">{name}</h3>
      <div className="list-checkboxes__list__container">
        {formikListCheckboxes.values.fields.map((field, index) => (
          <FormControlLabel
            key={field.id + field.fieldName}
            className="list-checkboxes__list__container__form"
            control={
              <Checkbox
                icon={<img src={carImages.checkboxBase} className="icons" />}
                checkedIcon={<img src={carImages.checkbox} className="icons" />}
                className="list-checkboxes__list__container__check"
                checked={field.checked}
                onChange={(e) => {
                  formikListCheckboxes.setFieldValue(`fields[${index}]`, {
                    ...field,
                    checked: e.currentTarget.checked,
                  });
                  formikListCheckboxes.handleSubmit();
                }}
              />
            }
            label={field.name}
          />
        ))}
      </div>
    </div>
  );
};
