import { createContext, FC, useEffect, useState } from "react";
import { IndexCarSearchForm } from "../views/car/index/form";
import { DropdownFields } from "../components/dropdownCheckboxes/form";

const mockStatus = [
  {
    id: 0,
    name: "Activo",
    fieldName: "activo",
    checked: true,
  },
  {
    id: 1,
    name: "Incompleto",
    fieldName: "incompleto",
    checked: true,
  },
  {
    id: 2,
    name: "Pausado",
    fieldName: "pausa",
    checked: true,
  },
  {
    id: 3,
    name: "Listado aprobado",
    fieldName: "listado_aprobado",
    checked: true,
  },
  {
    id: 4,
    name: "Listado pendiente",
    fieldName: "listado_pendiente",
    checked: true,
  },
];

const mockType = [
  {
    id: 0,
    name: "Compacto",
    fieldName: "compacto",
    checked: true,
  },
  {
    id: 1,
    name: "Sedán",
    fieldName: "sedan",
    checked: true,
  },
  {
    id: 2,
    name: "Coupé",
    fieldName: "coupe",
    checked: true,
  },
  {
    id: 3,
    name: "SUV",
    fieldName: "suv",
    checked: true,
  },
  {
    id: 4,
    name: "Minivan",
    fieldName: "minivan",
    checked: true,
  },
  {
    id: 5,
    name: "Van",
    fieldName: "van",
    checked: true,
  },
  {
    id: 6,
    name: "Troca",
    fieldName: "troca",
    checked: true,
  },
];

const mockTransmission = [
  {
    id: 0,
    name: "Automatica",
    fieldName: "automatica",
    checked: true,
  },
  {
    id: 1,
    name: "Manual",
    fieldName: "manual",
    checked: true,
  },
];

export const FiltersCarIndexContext = createContext<{
  status: DropdownFields[];
  type: DropdownFields[];
  transmission: DropdownFields[];
}>({
  status: mockStatus,
  type: mockType,
  transmission: mockTransmission,
});

export const IndexFiltersProvider: FC<{
  children: React.ReactNode;
  values: IndexCarSearchForm;
}> = ({ children, values }) => {
  const [status, setStatus] = useState<DropdownFields[]>(mockStatus);
  const [type, setType] = useState<DropdownFields[]>(mockType);
  const [transmission, setTransmission] =
    useState<DropdownFields[]>(mockTransmission);

  useEffect(() => {
    if (values) {
      setStatus((prev) =>
        prev.map((sta) => ({
          ...sta,
          checked: values.status.includes(sta.id),
        }))
      );
      setType((prev) =>
        prev.map((sta) => ({
          ...sta,
          checked: values.type.includes(sta.id),
        }))
      );
      setTransmission((prev) =>
        prev.map((sta) => ({
          ...sta,
          checked: values.transmission.includes(sta.id),
        }))
      );
    }
  }, [values]);

  return (
    <FiltersCarIndexContext.Provider value={{ status, type, transmission }}>
      {children}
    </FiltersCarIndexContext.Provider>
  );
};
