import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import {
  CarStatusIndex,
  CarType,
  Deactivated,
  Transmission,
} from "../../../models/car/types";
import { getCarsByUser } from "../../../services/car";
import { TranslationContext } from "../../driverReservation/show/component";
import "./styles.scss";
import images from "../../../assets/images/a_cars/index";
import { CarsCard } from "../../../components/carCards/component";
import carImages from "../../../assets/images/car/index";
import navbarImages from "../../../assets/images/navbar/index";
import Loading from "../../../components/loading/component";
import { Helmet } from "react-helmet";
import { IconButton, InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import { useWindowSize } from "../../../hooks/windowSize";
import { useFormik } from "formik";
import {
  IndexCarSearchForm,
  IndexCarSearchSchema,
  mockIndexCarSearch,
} from "./form";
import { useDebounce } from "../../../hooks/debounce";
import { DropdownProps } from "../../../components/dropdownCheckboxes/form";
import { FilterCarIndex } from "../../../components/modals/filterCarIndex/FilterCarIndex";
import { IndexFiltersProvider } from "../../../providers/indexFiltersProvider";
import { ActionsCarIndex } from "../../../components/actionsCarIndex/ActionsCarIndex";

export const CarIndex = () => {
  const [openFilters, setOpenFilters] = useState<boolean>(false);
  const [indexSearch, setIndexSearch] =
    useState<IndexCarSearchForm>(mockIndexCarSearch);
  const { data: cars, isLoading } = useQuery(
    ["cars", indexSearch],
    () => getCarsByUser(indexSearch),
    { enabled: !!indexSearch }
  );
  const { t } = useTranslation(["car", "routes"]);
  const windowSize = useWindowSize();
  const fetchEditIndexCarSearch = (values: IndexCarSearchForm) => {
    setIndexSearch(values);
  };

  const formikIndexCarSearch = useFormik<IndexCarSearchForm>({
    initialValues: {
      carName: "",
      status: Object.values(CarStatusIndex).filter(
        (value) => typeof value === "number"
      ) as CarStatusIndex[],
      type: Object.values(CarType).filter(
        (value) => typeof value === "number"
      ) as CarType[],
      transmission: Object.values(Transmission).filter(
        (value) => typeof value === "number"
      ) as Transmission[],
    },
    validationSchema: IndexCarSearchSchema,
    onSubmit: fetchEditIndexCarSearch,
  });

  const debouncedValue = useDebounce(formikIndexCarSearch.values, 1000);

  useEffect(() => {
    if (debouncedValue) {
      formikIndexCarSearch.handleSubmit();
    }
  }, [debouncedValue]);

  const handleSubmitCheckBoxes = (
    checkboxName: "status" | "type" | "transmission",
    values: Pick<DropdownProps, "fields">
  ) => {
    const checkBoxList = values.fields
      .filter((field) => field.checked)
      .map((field) => field.id);

    formikIndexCarSearch.setFieldValue(checkboxName, checkBoxList);
  };

  return (
    <>
      <Helmet>
        <title>{t("seo.index.title")}</title>
        <meta name="description" content={t("seo.index.description")} />
        <meta name="robots" content="noindex,follow" />
        <link rel="alternate" hrefLang="es" href="https://romio.mx/mis-autos" />
        <link
          rel="alternate"
          hrefLang="en"
          href="https://romio.mx/en/mis-autos"
        />
        <link
          rel="alternate"
          hrefLang="x-default"
          href="https://romio.mx/mis-autos"
        />
      </Helmet>
      <TranslationContext.Provider value={t}>
        <IndexFiltersProvider values={formikIndexCarSearch.values}>
          <article className="a-cars-container">
            <div className="a-cars__join-container">
              <div className="a-cars__title-container">
                <div className="a-cars__route">
                  <p className="a-cars__route__first">{t("index.manages")}</p>
                  <img
                    src={navbarImages.arrowRight}
                    className="a-cars__route__arrow"
                  />
                  <p className="a-cars__route__second">{t("index.cars")}</p>
                </div>
                <h1 data-testid="my-cars-title" className="a-cars__title">
                  {t("index.my_cars")}
                </h1>
                <p className="a-cars__subtitle">{t("index.manages_cars")}</p>
              </div>
              <div className="a-cars__search__container">
                <div className="a-cars__search">
                  <IconButton type="button" aria-label="search">
                    <SearchIcon className="a-cars__search__icon" />
                  </IconButton>
                  <InputBase
                    className="a-cars__search__input"
                    placeholder={t("index.search_placeholder")}
                    onChange={(e) =>
                      formikIndexCarSearch.setFieldValue(
                        "carName",
                        e.currentTarget.value
                      )
                    }
                  />
                </div>
                {windowSize < 991 ? (
                  <>
                    <button
                      className="a-cars__search__filter"
                      onClick={() => setOpenFilters(true)}
                    >
                      <FilterListIcon />
                    </button>
                    <Link
                      to={`/${t("manage", { ns: "routes" })}/${t("my_cars", {
                        ns: "routes",
                      })}/${t("upload_car", { ns: "routes" })}`}
                      className="a-cars__search__add"
                    >
                      <AddIcon />
                    </Link>
                  </>
                ) : (
                  <ActionsCarIndex
                    handleSubmitCheckBoxes={handleSubmitCheckBoxes}
                  />
                )}
              </div>
            </div>
            {(!cars || cars?.length === 0) && !isLoading && (
              <div className="cars-empty-list">
                <div className="cars-empty-list__container">
                  <img src={carImages.emptyCar} aria-hidden="true" />
                  <div className="cars-empty-list__information">
                    <h4 className="cars-empty-list__title">
                      {t("index.empty_view")}
                    </h4>
                    <p className="cars-empty-list__subtitle">
                      {t("index.empty_add")}
                    </p>
                  </div>
                  <Link
                    to={`/${t("manage", { ns: "routes" })}/${t("my_cars", {
                      ns: "routes",
                    })}/${t("upload_car", { ns: "routes" })}`}
                    data-testid="add-button"
                    className="btn btn-add"
                  >
                    <img src={images.plus} alt="plus" aria-hidden="true" />
                    <p>{t("index.add_car")}</p>
                  </Link>
                </div>
              </div>
            )}
            <div className="a-cars-container__reservation__card">
              <div className="car-card">
                <ul
                  className="list-group list-group-flush"
                  data-testid="car-list"
                >
                  {isLoading ? (
                    <Loading height="60vh" />
                  ) : (
                    cars?.map((car) => (
                      <React.Fragment key={car.id}>
                        {car.isReadyByHost != Deactivated.REMOVED && (
                          <CarsCard {...car} />
                        )}
                      </React.Fragment>
                    ))
                  )}
                </ul>
              </div>
            </div>
          </article>
          <FilterCarIndex
            open={openFilters}
            setOpen={setOpenFilters}
            handleSubmitCheckBoxes={handleSubmitCheckBoxes}
            values={formikIndexCarSearch.values}
          />
        </IndexFiltersProvider>
      </TranslationContext.Provider>
    </>
  );
};
