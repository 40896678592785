import { useContext, useRef, useState } from "react";
import {
  Car,
  CarStatus,
  Deactivated,
  StatusVehicleLicense,
  ValidateInfo,
} from "../../models/car/types";
import navbarImages from "../../assets/images/navbar/index";
import { TranslationContext } from "../../views/driverReservation/show/component";
import "../../views/car/index/styles.scss";
import {
  getTransmission,
  getType,
  getIsEcofriendly,
} from "../../views/car/index/types";
import images from "../../assets/images/a_cars/index";
import carImages from "../../assets/images/car/index";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import { Chip } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import CarMoreOptions from "../carMoreOptions/component";
import CarStatusInfo from "../carStatusInfo/component";
import CarDefaultImage from "../carDefaultImage/component";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useTypes } from "../../hooks/car/show/useTypes";
import { useQuery } from "react-query";
import { getAddressesByCarID } from "../../services/address";
import { useWindowSize } from "../../hooks/windowSize";
import useProfileData from "../profileData/useProfileData";

export const CarsCard = (car: Car) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const t = useContext(TranslationContext);
  const CAR_TYPES = useTypes();
  const windowSize = useWindowSize();
  const { imageProfile } = useProfileData();

  const { data: addressesData } = useQuery(
    ["addresses", car?.id],
    () => getAddressesByCarID(car?.id),
    {
      enabled: !!car,
    }
  );

  const circleIconColor = useRef(
    car.isReadyByHost === Deactivated.DISABLED
      ? "#D1D1D1"
      : car.status === CarStatus.PENDING || car.status === CarStatus.REJECTED
      ? "#2E7D32"
      : car.status === CarStatus.APPROVED &&
        car.docVehicleLicense === ValidateInfo.VALIDATED &&
        car.statusVehicleLicense !== StatusVehicleLicense.EXPIRED &&
        car.isReadyByHost === Deactivated.READY
      ? "#2E7D32"
      : "#C62828"
  );

  const statusBackgroundColor = useRef(
    car.isReadyByHost === Deactivated.DISABLED
      ? "#F0F0F0"
      : car.status === CarStatus.PENDING || car.status === CarStatus.REJECTED
      ? "#E8F5E9"
      : car.status === CarStatus.APPROVED &&
        car.docVehicleLicense === ValidateInfo.VALIDATED &&
        car.statusVehicleLicense !== StatusVehicleLicense.EXPIRED &&
        car.isReadyByHost === Deactivated.READY
      ? "#E8F5E9"
      : "#FFEBEE"
  );

  const circleLabel = () =>
    car.isReadyByHost === Deactivated.DISABLED ? (
      t("index.car_status.disabled")
    ) : car.status === CarStatus.INCOMPLETE ? (
      t("index.car_status.incomplete")
    ) : car.status === CarStatus.PENDING ||
      car.status === CarStatus.REJECTED ? (
      <>
        <p>{t("index.car_status.active")}</p>
        <div className="list-group-item__status__icon__container">
          <img
            src={imageProfile?.url ? imageProfile.url : navbarImages.bLogin}
            className="list-group-item__status__icon"
          />
        </div>
      </>
    ) : car.status === CarStatus.APPROVED &&
      car.docVehicleLicense === ValidateInfo.VALIDATED &&
      car.statusVehicleLicense !== StatusVehicleLicense.EXPIRED &&
      car.isReadyByHost === Deactivated.READY ? (
      <>
        <p>{t("index.car_status.active")}</p>
        <div className="list-group-item__status__icon__container">
          <img
            src={imageProfile?.url ? imageProfile.url : navbarImages.bLogin}
            className="list-group-item__status__icon"
          />
          <img
            src={carImages.romioMarketplace}
            className="list-group-item__status__icon"
          />
        </div>
      </>
    ) : (
      <></>
    );

  const transmissionValue = useRef(getTransmission(car.transmission));
  const ecofriendlyValue = useRef(getIsEcofriendly(car.isEcoFriendly));
  const typeValue = useRef(getType(car.type));

  if (!car) return null;

  return (
    <li className="list-group-item">
      <div className="list-group-item__image__container">
        <Chip
          icon={
            <CircleIcon
              fontSize="inherit"
              sx={{
                color: circleIconColor.current,
              }}
            />
          }
          label={circleLabel()}
          className="list-group-item__status"
          sx={{ backgroundColor: statusBackgroundColor.current }}
        />
        <CarDefaultImage car={car} height={145} />
        {windowSize < 991 && (
          <button
            className="btn-options"
            data-testid="show-options-button"
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            <MoreHorizIcon />
          </button>
        )}
      </div>
      <div className="list-group-item__info__container">
        <div className="list-group-item__main__info">
          <h2>{`${car.brand} ${car.model} ${car.year}`}</h2>
          <p className="plate">
            <strong>{t("index.plates")}:</strong> {car.plate}
          </p>
          <p className="price">
            $<strong>{car.price}</strong>
            {" " + t("index.price_day")}
          </p>
          {windowSize > 990 && <CarStatusInfo {...car} />}
        </div>
        <div className="list-group-item__type">
          <h4 className="list-group-item__type__title">Datos importantes</h4>
          <div className="list-group-item__type__container">
            <div className="car-data-item">
              <img src={CAR_TYPES[car.type].src} alt="transmission icon" />
              <p>{typeValue.current}</p>
            </div>
            <div className="car-data-item">
              <img src={images?.transmission} alt="transmission icon" />
              <p>{transmissionValue.current}</p>
            </div>
            <div className="car-data-item">
              <img src={images?.ecoType} alt="ecological icon" />
              <p>{ecofriendlyValue.current}</p>
            </div>
            <div className="car-data-item">
              <PermIdentityOutlinedIcon />
              <p>{car.numSeats && car.numSeats < 0 ? 1 : car.numSeats}</p>
            </div>
            <div className="car-data-item">
              <img src={images?.bag} alt="bag icon" />
              <p>{car.numBags}</p>
            </div>
          </div>
        </div>
        <div className="list-group-item__direction">
          <h4 className="list-group-item__direction__title">Dirección</h4>
          <div className="list-group-item__direction__info">
            <img src={carImages.locationOn} alt="" />
            {addressesData && (
              <div className="list-group-item__direction__container">
                <p className="list-group-item__direction__comercial__name">
                  {addressesData[0].city}
                </p>
                <span className="list-group-item__direction__address">
                  {addressesData[0].completeAddress}
                </span>
              </div>
            )}
          </div>
        </div>
        {windowSize < 991 && <CarStatusInfo {...car} />}

        {windowSize > 990 && (
          <button
            className="btn-options"
            data-testid="show-options-button"
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            <MoreHorizIcon />
          </button>
        )}
      </div>
      <CarMoreOptions anchorEl={anchorEl} setAnchorEl={setAnchorEl} car={car} />
    </li>
  );
};
