import { useRef } from "react";
import {
  Car,
  StatusInsuranceCover,
  StatusVehicleLicense,
} from "../../../models/car/types";
import { useTranslation } from "react-i18next";

export const useStatus = (car: Car) => {
  const { t } = useTranslation(["car"]);

  const DOCUMENTS_STATUS_COLOR = useRef({
    0: "#FFAB03",
    1: "#FFAB03",
    2: "#373A3C;",
    3: "#EB5757",
    4: "#EB5757",
  });

  const DOCUMENTS_STATUS = useRef({
    0: t("index.status.not_uploaded"),
    1: t("index.status.in_revision"),
    2: t("index.status.approved"),
    3: t("index.status.rejected"),
    4: t("index.status.rejected"),
  });

  const CAR_STATUS = useRef({
    0: t("index.status.incomplete"),
    1: t("index.status.in_revision"),
    2: t("index.status.approved"),
    3: t("index.status.rejected"),
    4: t("index.status.rejected"),
  });

  const HAS_GPS_STATUS = useRef({
    0: t("index.status.gps.unknown"),
    1: t("index.status.gps.false"),
    2: t("index.status.gps.true"),
  });

  const HAS_GPS_STATUS_COLOR = useRef({
    0: "#FFAB03",
    1: "#EB5757",
    2: "#373A3C",
  });

  const docVehicleLicenseAboutToExpire = useRef(
    car.statusVehicleLicense === StatusVehicleLicense.ABOUT_TO_EXPIRE
  );

  const docVehicleLicenseExpired = useRef(
    car.statusVehicleLicense === StatusVehicleLicense.EXPIRED
  );

  const docInsuranceAboutToExpire = useRef(
    car.statusInsuranceCover === StatusInsuranceCover.ABOUT_TO_EXPIRE
  );

  const docInsuranceExpired = useRef(
    car.statusInsuranceCover === StatusInsuranceCover.EXPIRED
  );

  return {
    DOCUMENTS_STATUS_COLOR,
    DOCUMENTS_STATUS,
    CAR_STATUS,
    HAS_GPS_STATUS,
    HAS_GPS_STATUS_COLOR,
    docVehicleLicenseAboutToExpire,
    docVehicleLicenseExpired,
    docInsuranceAboutToExpire,
    docInsuranceExpired,
  };
};
