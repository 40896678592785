import {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import navbarImages from "../../assets/images/navbar/index";
import { useWindowSize } from "../../hooks/windowSize";
import { LinkTo } from "../adminNavbar/AdminNavbar";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { useTranslation } from "react-i18next";
import "./AdminSidebar.scss";
import { useAdminRoutes } from "../../hooks/useAdminRoutes";
import { Link } from "react-router-dom";
import { handleClickOutside } from "../../lib/helpers/dropdownBoxActions";
import { Chip } from "@mui/material";

export const AdminSidebar = ({
  showSideBar,
  setShowSideBar,
}: {
  showSideBar: boolean;
  setShowSideBar: Dispatch<SetStateAction<boolean>>;
}) => {
  const adminSidebar = useRef<HTMLInputElement>(null);
  const { t } = useTranslation(["navbar"]);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const { activeSection, activeDropdown, menu, selectedSection } =
    useAdminRoutes();

  useEffect(() => {
    const appearNavbar = () => {
      if (adminSidebar.current) {
        adminSidebar.current.style.left = `${(showSideBar ? 0 : -1) * 400}px`;
        document.body.style.overflow = showSideBar ? "hidden" : "auto";
      }
    };
    appearNavbar();
  }, [showSideBar]);

  return (
    <>
      <div ref={adminSidebar} className="admin-sidebar">
        <div className="admin-sidebar__body">
          <div className="admin-sidebar__body__dropdown__container">
            <button
              className="admin-sidebar__body__dropdown__button"
              onClick={() => setShowDropdown((prev) => !prev)}
            >
              <div className="admin-sidebar__body__dropdown__button__section">
                <div className="admin-sidebar__body__dropdown__icon">
                  <img src={selectedSection.image} alt="" />
                </div>
                <p className="admin-sidebar__body__dropdown__title">
                  {t("navbar:admin_sidebar." + selectedSection.title)}
                </p>
              </div>
              <img src={navbarImages.expandAll} alt="expand all" />
            </button>
            {showDropdown && (
              <div className="admin-sidebar__body__dropdown">
                {menu.map((section) => (
                  <div
                    key={section.title}
                    className={`admin-sidebar__body__dropdown__section ${
                      section.active ? "active" : ""
                    }`}
                    onClick={() => {
                      activeSection(section);
                      setShowDropdown(false);
                    }}
                  >
                    <img
                      className="admin-sidebar__body__dropdown__section__icons"
                      src={section.image}
                      alt="home"
                    />
                    <p className="admin-sidebar__body__dropdown__section__title">
                      {t("navbar:admin_sidebar." + section.title)}
                    </p>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="admin-sidebar-menu__button__container">
            {selectedSection.title !== "home" &&
              selectedSection.routes.map((item) => {
                if (!item.subroute) {
                  return (
                    <Link
                      key={item.name}
                      to={item.route}
                      onClick={() => setShowSideBar(false)}
                      className={`admin-sidebar-menu__button ${
                        item.active ? "active" : ""
                      }`}
                    >
                      <div className="admin-sidebar__button__content">
                        {t("navbar:admin_sidebar.routes." + item.name)}
                        {item.workInProgress && (
                          <Chip
                            label={t("navbar:admin_sidebar.work_in_progress")}
                            className="admin-sidebar__status"
                          />
                        )}
                      </div>
                    </Link>
                  );
                } else {
                  return (
                    <Fragment key={item.name}>
                      <a
                        className={`admin-sidebar-menu__button ${
                          item.active ? "active" : ""
                        }`}
                        onClick={() =>
                          activeDropdown(selectedSection, item.name)
                        }
                      >
                        <div className="admin-sidebar__button__content">
                          {t("navbar:admin_sidebar.routes." + item.name)}
                          {item.workInProgress && (
                            <Chip
                              label={t("navbar:admin_sidebar.work_in_progress")}
                              className="admin-sidebar__status"
                            />
                          )}
                        </div>
                        {item.icon && (
                          <img
                            src={item.icon}
                            alt=""
                            className={`${item.active ? "turn" : ""}`}
                          />
                        )}
                      </a>
                      {item.active &&
                        item.subroute?.map((subroute) => (
                          <div
                            className="admin-sidebar-menu__subroute__container"
                            key={subroute.name}
                          >
                            {subroute.active && (
                              <div
                                className={`admin-sidebar-menu__button__list`}
                              ></div>
                            )}
                            {subroute.isExternal ? (
                              <a
                                href={subroute.route}
                                key={subroute.name}
                                target="_blank"
                                className="admin-sidebar-menu__subroute"
                              >
                                {t(
                                  "navbar:admin_sidebar.routes." + subroute.name
                                )}
                                {subroute.icon && (
                                  <img src={subroute.icon} alt="" />
                                )}
                              </a>
                            ) : (
                              <Link
                                to={subroute.route}
                                key={subroute.name}
                                onClick={() => setShowSideBar(false)}
                                className="admin-sidebar-menu__subroute"
                              >
                                {t(
                                  "navbar:admin_sidebar.routes." + subroute.name
                                )}
                                {item.workInProgress && (
                                  <Chip
                                    label={t(
                                      "navbar:admin_sidebar.work_in_progress"
                                    )}
                                    className="admin-sidebar__status"
                                  />
                                )}
                                {subroute.icon && (
                                  <img src={subroute.icon} alt="" />
                                )}
                              </Link>
                            )}
                          </div>
                        ))}
                    </Fragment>
                  );
                }
              })}
          </div>
        </div>
        <div className="admin-sidebar__footer">
          <LinkTo to={"https://romio.mx/"} />
          <div className="admin-sidebar__footer__support">
            <HelpOutlineOutlinedIcon /> Soporte
          </div>
          <img
            src={navbarImages.powerLogo}
            alt="power logo"
            className="power"
          />
        </div>
      </div>
      {showSideBar && (
        <div
          className="black-background"
          onClick={() => setShowSideBar(false)}
        ></div>
      )}
    </>
  );
};
