import { SetStateAction, Dispatch, useEffect, useState } from "react";
import { Modal } from "@mui/material";
import "./styles.scss";
import { getInsuranceAndRefresh } from "../../../services/insurance";
import { Car } from "../../../models/car/types";
import { useTranslation } from "react-i18next";
import { Host } from "../../../models/host/types";
import reservationImages from "../../../assets/images/reservations";
import { Insurance, InsuranceType } from "../../../models/insurance/types";
import dayjs from "dayjs";
import Loading from "../../loading/component";
import { isValidHostCOwnInsurance } from "../../../lib/helpers/status";
import { useQuery } from "react-query";
import { getHostByUserId } from "../../../services/host";
import { capitalize } from "../../../lib/helpers/textHandler";
import { Link } from "react-router-dom";

type CarInsurance = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  car: Car;
  hostCommercial?: Host;
};

// ASK ALDAIR
const CarInsurance = ({ open, setOpen, car, hostCommercial }: CarInsurance) => {
  const [insuranceData, setInsuranceData] = useState<Insurance | null>();
  const { t } = useTranslation(["car", "routes"]);
  const existHost = !!hostCommercial || false;
  const { data: hostC } = useQuery(
    ["hostC", car.userId],
    () => getHostByUserId(car.userId.toString() || ""),
    {
      enabled: existHost,
    }
  );

  const getInsurance = async () => {
    try {
      const data = await getInsuranceAndRefresh(car.id);
      if (!data) {
        setInsuranceData(null);
        return;
      }
      const date = dayjs().toString();

      setInsuranceData({
        ...data,
        lastUpdate: date,
        depositAmount: data.depositAmount,
      });
    } catch (err) {
      setInsuranceData(null);
    }
  };

  useEffect(() => {
    if (open) {
      getInsurance();
    }
  }, [open]);

  const insuranceInfo =
    insuranceData === undefined ? (
      <Loading height="10vh" />
    ) : insuranceData !== null ? (
      <>
        <div className="car-data-items">
          {hostCommercial &&
            hostC &&
            isValidHostCOwnInsurance(hostC) &&
            insuranceData.type === InsuranceType.HOST_INSURANCE && (
              <div className="car-data-items__logo__container">
                <p>{t("index.modals.insurance.own_insurance_title")}</p>
              </div>
            )}

          {insuranceData.type !== InsuranceType.HOST_INSURANCE && (
            <div className="car-data-items__logo__container">
              <p>{t("index.modals.insurance.our_insurance_title")}</p>
              {insuranceData.type === InsuranceType.QUALITAS ? (
                <img src={reservationImages.qualitas} alt="" />
              ) : (
                <img src={reservationImages.hdi} alt="" />
              )}
            </div>
          )}

          <div className="car-data-items__main__info">
            <p>
              {t("index.modals.insurance.data.car")}
              <span>{`${car.brand} ${car.model} ${car.year}`}</span>
            </p>
            <p>
              {t("index.modals.insurance.data.serial_number")}
              <span>{car.niv}</span>
            </p>
          </div>

          <div className="car-data-items__prices">
            {hostCommercial &&
            hostC &&
            isValidHostCOwnInsurance(hostC) &&
            insuranceData.type !== InsuranceType.QUALITAS ? (
              <>
                <p>
                  {t("index.modals.own_insurance.charge")}
                  <span>
                    {insuranceData?.customerPrice === undefined
                      ? "GRATIS"
                      : `$${insuranceData?.customerPrice}`}
                  </span>
                </p>
                <p>
                  {t("index.modals.own_insurance.deposit")}
                  <span>
                    {insuranceData?.depositAmount === undefined
                      ? "GRATIS"
                      : `$${insuranceData?.depositAmount}`}
                  </span>
                </p>
              </>
            ) : (
              <>
                {insuranceData.type === InsuranceType.QUALITAS && (
                  <>
                    <p>
                      {t("index.modals.insurance.data.last_update")}
                      <span>
                        {dayjs(insuranceData?.lastUpdate).format("DD/MM/YYYY")}
                      </span>
                    </p>
                    <p>
                      {t("index.modals.insurance.data.nominal_value")}
                      <span>
                        {new Intl.NumberFormat("es-MX", {
                          style: "currency",
                          currency: "MXN",
                          maximumSignificantDigits: 6,
                        }).format(parseFloat(insuranceData?.marketValue))}
                      </span>
                    </p>
                  </>
                )}
                <p>
                  {t("index.modals.insurance.data.price_per_day")}
                  <span>
                    {new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                      maximumSignificantDigits: 6,
                    }).format(parseFloat(insuranceData.customerPrice))}
                  </span>
                </p>
              </>
            )}
          </div>
        </div>

        <div className="list">
          <p>
            {hostCommercial &&
            hostC &&
            isValidHostCOwnInsurance(hostC) &&
            insuranceData.type === InsuranceType.HOST_INSURANCE
              ? t("index.modals.own_insurance.title")
              : insuranceData.type === InsuranceType.QUALITAS
              ? t("index.modals.insurance.list.title")
              : insuranceData.type === InsuranceType.HDI
              ? t("index.modals.insurance.hdi_list.title")
              : ""}
          </p>
          <div className="divider"></div>
          {hostCommercial &&
            hostC &&
            isValidHostCOwnInsurance(hostC) &&
            insuranceData.type === InsuranceType.HOST_INSURANCE && (
              <div className="own-insurance">
                <p>{t("index.modals.own_insurance.message")}</p>
              </div>
            )}

          {insuranceData.type === InsuranceType.QUALITAS && (
            <ul>
              <li>
                <span>
                  {t("index.modals.insurance.list.material_data_key")}
                </span>
                <p>{t("index.modals.insurance.list.material_data_value")}</p>
              </li>
              <li>
                <span>{t("index.modals.insurance.list.theft_key")}</span>
                <p>{t("index.modals.insurance.list.theft_value")}</p>
              </li>
              <li>
                <span>
                  {t("index.modals.insurance.list.civil_liability_key")}
                </span>
                <p>{t("index.modals.insurance.list.civil_liability_value")}</p>
              </li>
              <li>
                <span>
                  {t("index.modals.insurance.list.medical_expenses_key")}
                </span>
                <p>{t("index.modals.insurance.list.medical_expenses_value")}</p>
              </li>
              <li>
                <span>
                  {t("index.modals.insurance.list.legal_expenses_key")}
                </span>
                <p>{t("index.modals.insurance.list.legal_expenses_value")}</p>
              </li>
              <li>
                <span>{t("index.modals.insurance.list.qualitas")}</span>
                <p>{t("index.modals.insurance.list.legal_expenses_value")}</p>
              </li>
              <li>
                <span>{t("index.modals.insurance.list.car_accident_key")}</span>
                <p>$100,000.00</p>
              </li>
            </ul>
          )}

          {insuranceData.type === InsuranceType.HDI && (
            <ul>
              <li>
                <span>
                  {t("index.modals.insurance.hdi_list.material_data_key")}
                </span>
                <p>
                  {t("index.modals.insurance.hdi_list.material_data_value")}
                </p>
              </li>
              <li>
                <span>{t("index.modals.insurance.hdi_list.theft_key")}</span>
                <p>{t("index.modals.insurance.hdi_list.theft_value")}</p>
              </li>
              <li>
                <span>
                  {t("index.modals.insurance.hdi_list.civil_liability_key")}
                </span>
                <p>
                  {t("index.modals.insurance.hdi_list.civil_liability_value")}
                </p>
              </li>
              <li>
                <span>
                  {t("index.modals.insurance.hdi_list.medical_expenses_key")}
                </span>
                <p>
                  {t("index.modals.insurance.hdi_list.medical_expenses_value")}
                </p>
              </li>
              <li>
                <span>
                  {t("index.modals.insurance.hdi_list.legal_expenses_key")}
                </span>
                <p>
                  {t("index.modals.insurance.hdi_list.legal_expenses_value")}
                </p>
              </li>
              <li>
                <span>{t("index.modals.insurance.hdi_list.qualitas")}</span>
                <p>
                  {t("index.modals.insurance.hdi_list.legal_expenses_value")}
                </p>
              </li>
              <li>
                <span>
                  {t("index.modals.insurance.hdi_list.car_accident_key")}
                </span>
                <p>$100,000.00</p>
              </li>
            </ul>
          )}
        </div>

        {insuranceData.type !== InsuranceType.HOST_INSURANCE && (
          <div className="help">
            <span>
              {t("index.modals.insurance.help", {
                insurance: capitalize(InsuranceType[insuranceData.type]),
              })}
            </span>
            <Link
              to={`/${t("protect", { ns: "routes" })}/${t("insurances", {
                ns: "routes",
              })}`}
            >
              {t("index.modals.insurance.help_protect")}
            </Link>
            <span>{t("index.modals.insurance.help_next")}</span>
          </div>
        )}

        {hostCommercial &&
          hostC &&
          isValidHostCOwnInsurance(hostC) &&
          insuranceData.type === InsuranceType.HOST_INSURANCE && (
            <div className="help">{t("index.modals.insurance.help_own")}</div>
          )}
      </>
    ) : (
      <div>
        <p>{t("index.modals.insurance.empty_message")}</p>
      </div>
    );

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      className="car-insurance-modal"
    >
      <div
        className={
          !insuranceData ? "car-data-empty" : "car-insurance-container"
        }
      >
        <div className="car-data">
          <div className="car-data__title">
            <h4>{t("index.modals.insurance.title")}</h4>
            <button
              type="button"
              className="btn-close"
              onClick={() => setOpen(false)}
            ></button>
          </div>
          {insuranceInfo}
        </div>
      </div>
    </Modal>
  );
};

export default CarInsurance;
