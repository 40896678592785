import { FormikHelpers } from "formik";
import * as Yup from "yup";

export interface DropdownProps {
  fetchDropdown: (
    values: Pick<DropdownProps, "fields">,
    formikHelpers: FormikHelpers<Pick<DropdownProps, "fields">>
  ) => void;
  fields: DropdownFields[];
  name: string;
}

export interface DropdownFields {
  id: number;
  name: string;
  fieldName: string;
  checked: boolean;
}

export const DropdownCheckboxesSchema: Yup.SchemaOf<
  Pick<DropdownProps, "fields">
> = Yup.object().shape({
  fields: Yup.array().of(
    Yup.object().shape({
      id: Yup.number().required(),
      name: Yup.string().required(),
      fieldName: Yup.string().required(),
      checked: Yup.boolean().required(),
    })
  ),
});
