import { useState } from "react";
import navbarImages from "../../assets/images/navbar/index";
import { useFormik } from "formik";
import { DropdownCheckboxesSchema, DropdownProps } from "./form";
import { Checkbox, FormControlLabel } from "@mui/material";
import carImages from "../../assets/images/car/index";
import "./DropdownCheckboxes.scss";

export const DropdownCheckboxes = ({
  name,
  fields,
  fetchDropdown,
}: DropdownProps) => {
  const [dropdown, setDropdown] = useState<boolean>(false);

  const formikDropdownCheckboxes = useFormik<Pick<DropdownProps, "fields">>({
    initialValues: {
      fields,
    },
    validationSchema: DropdownCheckboxesSchema,
    onSubmit: fetchDropdown,
  });

  return (
    <div className="dropdown-checkboxes">
      <button
        className="dropdown-checkboxes__button"
        onClick={() => setDropdown((prev) => !prev)}
      >
        {name}
        <img
          src={navbarImages.downArrow}
          alt=""
          className={`${dropdown ? "turn" : ""}`}
        />
      </button>
      {dropdown && (
        <div className="dropdown-checkboxes__dropdown__container">
          {formikDropdownCheckboxes.values.fields.map((field, index) => (
            <FormControlLabel
              key={field.id + field.fieldName}
              className="dropdown-checkboxes__dropdown__container__form"
              control={
                <Checkbox
                  icon={<img src={carImages.checkboxBase} className="icons" />}
                  checkedIcon={
                    <img src={carImages.checkbox} className="icons" />
                  }
                  className="dropdown-checkboxes__dropdown__container__check"
                  checked={field.checked}
                  onChange={(e) => {
                    formikDropdownCheckboxes.setFieldValue(`fields[${index}]`, {
                      ...field,
                      checked: e.currentTarget.checked,
                    });
                    formikDropdownCheckboxes.handleSubmit();
                  }}
                />
              }
              label={field.name}
            />
          ))}
        </div>
      )}
    </div>
  );
};
